import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import others weights with the same name and include the as in the fontawesome library.add - then use with 
// import { faHome as faHomeLight } from '@fortawesome/pro-light-svg-icons/faHome';
// To put in the jsx use
// <FontAwesomeIcon icon={["fal", "home"]}/>

import {faCircleNotch} from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import {faEnvelopeOpenText} from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText';
import {faUserClock} from '@fortawesome/pro-duotone-svg-icons/faUserClock';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faCalendarAlt} from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import {faCalendarRange} from '@fortawesome/pro-regular-svg-icons/faCalendarRange';
import {faComment} from '@fortawesome/pro-regular-svg-icons/faComment';
import {faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import {faGaugeMax} from '@fortawesome/pro-regular-svg-icons/faGaugeMax';
import {faUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faLaptopMobile} from '@fortawesome/pro-regular-svg-icons/faLaptopMobile';
import {faFileSpreadsheet} from '@fortawesome/pro-regular-svg-icons/faFileSpreadsheet';
import {faDownLong} from '@fortawesome/pro-regular-svg-icons/faDownLong';
import {faEye} from '@fortawesome/pro-regular-svg-icons/faEye';
import {faEyeSlash} from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faCaretUp} from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faCommentSmile} from '@fortawesome/pro-regular-svg-icons/faCommentSmile';
import {faChartLineUp} from '@fortawesome/pro-regular-svg-icons/faChartLineUp';
import {faGasPump} from '@fortawesome/pro-regular-svg-icons/faGasPump';
import {faStars} from '@fortawesome/pro-regular-svg-icons/faStars';
import {faHourglassClock} from '@fortawesome/pro-regular-svg-icons/faHourglassClock';
import {faCopyright} from '@fortawesome/pro-regular-svg-icons/faCopyright';
import {faAccessibleIcon} from '@fortawesome/free-brands-svg-icons/faAccessibleIcon';
import {faClock} from '@fortawesome/pro-regular-svg-icons/faClock';
import {faMoneyBillWave} from '@fortawesome/pro-regular-svg-icons/faMoneyBillWave';
import {faCartShopping} from '@fortawesome/pro-regular-svg-icons/faCartShopping';
import {faBellOn} from '@fortawesome/pro-regular-svg-icons/faBellOn';
import {faPhoneSlash} from '@fortawesome/pro-solid-svg-icons/faPhoneSlash';
import {faPhoneMissed} from '@fortawesome/pro-solid-svg-icons/faPhoneMissed';
import {faPhonePlus} from '@fortawesome/pro-solid-svg-icons/faPhonePlus';
import {faPenField} from '@fortawesome/pro-solid-svg-icons/faPenField';
import {faClone} from '@fortawesome/pro-regular-svg-icons/faClone';
import {faSquareExclamation} from '@fortawesome/pro-solid-svg-icons/faSquareExclamation';
import {faCommentPlus} from '@fortawesome/pro-solid-svg-icons/faCommentPlus';
import {faPhoneArrowUpRight} from '@fortawesome/pro-regular-svg-icons/faPhoneArrowUpRight';
import {faSpinnerThird} from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import {faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket';
import {faGlobe} from '@fortawesome/pro-regular-svg-icons/faGlobe';
import {faTable} from '@fortawesome/pro-regular-svg-icons/faTable';


library.add(
    faCircleNotch,
    faEnvelopeOpenText,
    faUserClock,
    faBars,
    faTimes,
    faCalendarAlt,
    faComment,
    faMapMarkerAlt,
    faCalendarRange,
    faGaugeMax,
    faUsers,
    faLaptopMobile,
    faFileSpreadsheet,
    faDownLong,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretUp,
    faCommentSmile,
    faChartLineUp,
    faGasPump,
    faStars,
    faHourglassClock,
    faAccessibleIcon,
    faCopyright,
    faClock,
    faMoneyBillWave,
    faCartShopping,
    faBellOn,
    faPhoneSlash,
    faPhoneMissed,
    faPenField,
    faChevronDown,
    faChevronUp,
    faClone,
    faSquareExclamation,
    faCommentPlus,
    faPhoneArrowUpRight,
    faPhonePlus,
    faSpinnerThird,
    faArrowRightFromBracket,
    faGlobe,
    faTable
);


export default FontAwesomeIcon;