import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import { lazy, Suspense } from 'react';

import {AppContext, AppStateProvider} from './AppContext';

import LoginPage from './Login/LoginPage';
import Dashboard from './Dashboard/Dashboard';
import MainPage from './Main/MainPage';
import Feedback from './Feedback/Feedback';
import StationReports from './StationReports/StationReports';
import MonthlyStationReports from './StationReports/MonthlyStationReports';
import Stations from './Stations/Stations';
import Issues from './Issues/Issues';

import UserStats from './UserStats/UserStats';
import Users from './Users/Users';
const DeviceStats = lazy(() => import('./DeviceStats/DeviceStats'));
const BrandStats = lazy(() => import('./BrandStats/BrandStats'));
const CountryStats = lazy(() => import('./CountryStats/CountryStats'));
const FuelStats = lazy(() => import('./FuelStats/FuelStats'));
const TestRuns = lazy(() => import('./TestRuns/TestRuns'));
const TestRunResult = lazy(() => import('./TestRuns/TestRunResult'));
const Station = lazy(() => import('./Station/Station'));
const Sessions = lazy(() => import('./Sessions/Sessions'));

const ShellAdminUsers = lazy(() => import('./AdminUsers/ShellAdminUsers'));

function App() {
  return (
    <AppStateProvider>
        <AppContext.Consumer>
          {(appState) => (

            <BrowserRouter>
              <Routes>
                <Route path="/" 
                  element={<LoginPage />}
                />
                <Route path="/login/:id" 
                  element={<LoginPage />}
                />

                {/* Routes allowed when logged in */}
                {appState.token &&

                  <Route path="/main" element={
                      <MainPage />
                    }>

                    <Route path="userstats" element={
                      <UserStats />
                    }/>

                    <Route path="devicestats" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <DeviceStats />
                      </Suspense>
                    }/>

                    <Route path="brandstats" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <BrandStats />
                      </Suspense>
                    }/>

                    <Route path="countrystats" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <CountryStats />
                      </Suspense>
                    }/>

                    <Route path="fuelstats" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <FuelStats />
                      </Suspense>
                    }/>

                    <Route path="feedback" element={
                      <Feedback />
                    }/>

                    <Route path="stations" element={
                      <Stations />
                    }/>

                    <Route path="sessions" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Sessions />
                      </Suspense>
                      
                    }/>

                    <Route path="testruns" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <TestRuns />
                      </Suspense>
                    }/>

                    <Route path="testruns/:idtestrun" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <TestRunResult />
                      </Suspense>
                    }/>

                    <Route path="stations/:idstations" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Station />
                      </Suspense>
                    }/>

                    <Route path="stationreports" element={
                      <StationReports />
                    }/>

                    <Route path="monthlystationreports" element={
                      <MonthlyStationReports />
                    }/>

                    <Route path="currentissues" element={
                      <Issues />
                    }/>

                    <Route path="users" element={
                      <Users />
                    }/>

                    <Route path="shelladminusers" element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <ShellAdminUsers />
                      </Suspense>
                    }/>

                    <Route index element={
                      <Dashboard />
                      }
                    />

                  </Route>
                }

                <Route path="*" element={<LoginPage />}/>

              </Routes>
            </BrowserRouter>

          )}
        </AppContext.Consumer>
      </AppStateProvider>
);
}

export default App;
