import { StationsPerformanceCard } from './StationsPerformanceCard';
import './StationReports.scss';
import MobileRotate from '../Components/MobileRotate/MobileRotate';

export const MonthlyStationReports = () => {

    return(
        <div className="station-reports">
            <MobileRotate />
            <StationsPerformanceCard monthly/>
        </div>
    )
}

export default MonthlyStationReports;