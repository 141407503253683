
export const getEndOfToday = () => {
    const date = new Date();
    date.setHours(23,59,59,999);
    return(date);
}

/**
 * Generate a list of months for use to select month date ranges
 * @returns 
 */
export const generateMonthsAndYears = () => {
    const startYear = 2017; // Starting year
    const startMonth = 0; // January (0-based index)
    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];

    const result = [];
    const currentDate = new Date();
    let currentYear = currentDate.getUTCFullYear();
    let currentMonth = currentDate.getUTCMonth(); // 0-based index for current month

    // Loop until reaching January 2016
    while (currentYear > startYear || (currentYear === startYear && currentMonth >= startMonth)) {
        const startMonth = new Date(Date.UTC(currentYear, currentMonth, 1, 0, 0, 0)); // First minute in UTC
        const endMonth = new Date(Date.UTC(currentYear, currentMonth + 1, 0, 23, 59, 59)); // Last minute in UTC
        
        result.push({
            display: `${months[currentMonth]} ${currentYear}`,
            month: months[currentMonth],
            year: currentYear,
            range: [startMonth, endMonth]
        });

        currentMonth--;
        if (currentMonth < 0) {
            currentMonth = 11; // December
            currentYear--; // Move to the previous year
        }
    }

    return result;
}

export const getCurrentMonthStartAndFinish = () => {
    const currentDate = new Date();
    let currentYear = currentDate.getUTCFullYear();
    let currentMonth = currentDate.getUTCMonth(); // 0-based index for current month
    const startMonth = new Date(Date.UTC(currentYear, currentMonth, 1, 0, 0, 0)); // First minute in UTC
    const endMonth = new Date(Date.UTC(currentYear, currentMonth + 1, 0, 23, 59, 59)); // Last minute in UTC
    console.log(startMonth, endMonth);
    return([startMonth, endMonth]);
}